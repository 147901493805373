import React from 'react'
import { useNavigate } from 'react-router-dom'
import { tick_icon } from '../../assets/imgs'

const PayNowAutopayOn = () => {
    const navigate = useNavigate()
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div className='text-center mt-2'>
                <img onClick={()=>{navigate("/on-registry")}} src={tick_icon} className='tick-icon pointer' alt="" />
                <h4 className=' f3 fs-sm-12 fs-md-15 fs-lg-21 fs-xl-23 fs-xxl-24  mb-0 mt-3'> Payment Success!</h4>
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16  mt-1 mb-0 sec1'><span className='white'>$3.64 CAD</span> Revalie Ottawa, Ottawa ON</p>
            </div>
        </div>
    )
}

export default PayNowAutopayOn