import React from 'react'
import { useNavigate } from 'react-router-dom';
import { tick_icon } from '../../assets/imgs';

const AutopaySuccess = () => {
    const navigate = useNavigate();
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div className='text-center mt-4'>
                <img src={tick_icon} className='tick-icon' alt="" />
                <h4 className=' f3 fs-sm-12 fs-md-15 fs-lg-21 fs-xl-23 fs-xxl-24  mb-0 mt-3'> Auto-pay Success!</h4>
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16  mb-0 '>You will be billed when you leave the lot! </p>
            </div>
            <div className='d-flex flex-column ac-jc'>
                <div className='d-flex ac-jc pb-3'>
                    <button onClick={() => { navigate("/paybycard") }} className='inbox pay-btn f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer white'>View more in Web</button>
                </div>
                <div className='d-flex ac-jc pb-3'>
                     <button onClick={() => { navigate("/paybycard") }} className='confirm-btn f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer d-flex ac-jc'>Download App</button>
                </div>
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec mb-0 p-tag '>The app allows for real-time notifications, billing history, and exclusive offers! </p>
            </div>
        </div>
    )
}

export default AutopaySuccess