import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apple_icon, cancel_icon } from '../../assets/imgs'

const PlateAutopayOn = () => {
    const navigate = useNavigate()
    const [timeInMinutes, setTimeInMinutes] = useState(0)

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60)
        const mins = minutes % 60
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`
    }

    const incrementTime = () => {
        setTimeInMinutes((prevTime) => prevTime + 1)
    }

    const decrementTime = () => {
        setTimeInMinutes((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    };
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div>
                <div className=' d-flex ac-jc'>
                    <div className="inbox  ontario-box">
                        <p className=' f9 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 sec  mb-0'> ONTARIO</p>
                        <p className='f10 fs-sm-12 fs-md-14 fs-lg-20 fs-xl-22 fs-xxl-24 mb-0 letter-spacing'> ABCD123</p>
                    </div>
                </div>
                <div className='mt-1 d-flex ac-jc'>
                    <p onClick={()=>navigate("/licence-plate")} className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 pointer'> Change Plate</p>
                </div>
            </div>
            <div className='d-flex flex-column ac-jc '>
                <img src={cancel_icon} className='tick-icon' alt="" />
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 w-70 text-center mb-0 mt-2'>You’re plate was obstructed or covered, please let us know when you leave and you will automatically be billed.
                </p>
            </div>
            <div className='d-flex flex-column '>
                <button onClick={() => { navigate("/paynow-autopay-on") }} className='confirm-btn  f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer'>I’m leaving now</button>
                <button onClick={() => { navigate("/paynow-autopay-on") }} className='inbox white f5 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer d-flex ac-jc mt-2'>View more in App</button>
            </div>
        </div>
    )
}

export default PlateAutopayOn