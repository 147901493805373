import React from 'react'
import { useNavigate } from 'react-router-dom'
import { tick_icon } from '../../assets/imgs'

const PaymentSucess = () => {
    const navigate = useNavigate()
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div className='text-center '>
                <img src={tick_icon} className='tick-icon' alt="" />
                <h4 className=' f3 fs-sm-12 fs-md-15 fs-lg-21 fs-xl-23 fs-xxl-24  mb-0 mt-3'> Payment Success!</h4>
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16  mb-0 sec1 mt-1'><span className='white'>$3.64 CAD</span> Revalie Ottawa, Ottawa ON</p>
            </div>
            <button onClick={() => { navigate("/plate-not-read") }} className='inbox white f6 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 mt-2 px-5 mb-0 pointer'>Download App</button>
            <div className='mt-4  d-flex  flex-column ac-jc'>
                <div className="inbox  ontario-box">
                    <p className=' f9 fs-sm-11 fs-md-13 fs-lg-15 fs-xl-16 sec  mb-0'> ONTARIO</p>
                    <h4 className='f10 fs-sm-12 fs-md-14 fs-lg-20 fs-xl-22 fs-xxl-24 mb-0 letter-spacing'> ABCD123</h4>
                </div>
                <p className='f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 white mb-0 mt-3'>1 hour 30 minutes remaining....</p>
                <div className='mt-1 d-flex  ac-jc'>
                    <p className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 pointer'>Extend Duration</p>
                </div>
            </div>


        </div>
    )
}

export default PaymentSucess
