import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, TOKEN, URL } from "./Constants";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem(TOKEN);
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      // headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        body: payload,
        method: "POST",
      }),
    }),

    // LOGOUT
    logout: builder.mutation({
      query: () => ({ url: URL.LOGOUT, method: "POST" }),
    }),

    // LOCATIONS_LIST
    locationList: builder.query({
      query: () => URL.LOCATIONS_LIST,
    }),

    // ADD_LOCATION
    locationAdd: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATIONS_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION_DEVICES_ADD
    locationDevicesAdd: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_DEVICES_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // SSH STATUS
    sshStatus: builder.mutation({
      query: (payload) => ({
        url: URL.SSH_STATUS,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION_CONFIG_LIST
    locationConfigList: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_CONFIG,
        body: payload,
        method: "POST",
      }),
    }),
    //LOCATION CONFIG ADD
    locationConfigAdd: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_CONFIG_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    //LOCATION CONFIG EDIT
    locationConfigEdit: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_CONFIG_EDIT,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION CONFIG DELETE
    locationConfigDelete: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_CONFIG_DELETE,
        body: payload,
        method: "POST",
      }),
    }),
    // LOCATION CONFIG DELETE
    locationConfigView: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_CONFIG_VIEW,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION_DEVICES_UPDATE
    locationDevicesUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.LOCATION_DEVICES_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION_CAMERA_LIST
    locationCameraList: builder.query({
      query: (id) => `${URL.LOCATION_CAMERAS}${id}`,
    }),
    // LOCATION_VIEW
    locationView: builder.query({
      query: (id) => URL.LOCATION_VIEW + id,
    }),
    // LOCATION_EDIT
    locationEdit: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.LOCATION_EDIT}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),
    // LOCATION_DELETE
    locationDelete: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.LOCATION_DELETE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION_CAMERA_ADD
    locationCameraAdd: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_CAMERAS_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // LOCATION_CAMERA_UPDATE
    locationCameraUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: URL.LOCATION_CAMERAS_UPDATE + ID,
        body: payload,
        method: "POST",
      }),
    }),

    //LOCATION_CAMERA_VIEW
    locationCameraView: builder.query({
      query: (id) => URL.LOCATION_CAMERAS_VIEW + id,
    }),

    //LOCATION_CAMERA_DELETE
    locationCameraDelete: builder.query({
      query: (id) => URL.LOCATION_CAMERAS_DELETE + id,
    }),

    // LOCATION_CAMERA_POINT
    locationCameraPoint: builder.mutation({
      query: (payload) => ({
        url: URL.LOCATION_POINT,
        body: payload,
        method: "POST",
      }),
    }),

    // ADD-ADMIN
    addAdmin: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_ADMIN,
        body: payload,
        method: "POST",
      }),
    }),

    //LIST_ADMIN
    listAdmin: builder.query({
      query: () => ({
        url: URL.LIST_ADMIN,
      }),
    }),

    //UPDATE_ADMIN
    updateAdmin: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.UPDATE_ADMIN}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),

    // POINT VIEW
    pointList: builder.query({
      query: (id) => URL.LOCATION_POINT_LIST + id,
    }),

    //ADMIN_STATUS
    AdminStatus: builder.query({
      query: (ID) => ({
        url: `${URL.ADMIN_STATUS}${ID}/status`,
      }),
    }),

    //VIEW_ADMIN
    viewAdmin: builder.query({
      query: (id) => ({
        url: URL.VIEW_ADMIN + id,
      }),
    }),

    // VIP MASTER LIST
    vipmasterlist: builder.mutation({
      query: (payload) => ({
        url: URL.VIP_MASTER_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    // VIP MASTER RANGE LIST
    vipmasterlistRange: builder.mutation({
      query: (payload) => ({
        url: URL.VIP_LIST_DATE_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    // VIP MASTER STATUS
    vipMasterStatus: builder.query({
      query: (id) => URL.VIP_MASTER_STATUS + id + "/status",
    }),
    // VIP MASTER ADD
    vipMasterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.VIP_MASTER_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // VIP MASTER UPDATE
    vipMasterUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.VIP_MASTER_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),
    // VIP MASTER VIEW
    vipMasterView: builder.query({
      query: (id) => URL.VIP_MASTER_VIEW + id,
    }),

    // COMPLETE VIP MASTER LIST
    vipMasterCompleteList: builder.query({
      query: () => URL.VIP_MASTER_COMPLETE_LIST,
    }),

    // VIP MASTER DELETE
    vipMasterDelete: builder.query({
      query: (id) => URL.VIP_MASTER_DELETE + id,
    }),
    // PARKING MASTER LIST
    parkingmasterlist: builder.mutation({
      query: (payload) => ({
        url: URL.PARKING_MASTER_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    // PARKING MASTER STATUS
    parkingMasterStatus: builder.query({
      query: (id) => URL.PARKING_MASTER_STATUS + id + "/status",
    }),
    // PARKING MASTER DELETE
    parkingMasterDelete: builder.query({
      query: (id) => URL.PARKING_MASTER_DELETE + id,
    }),

    // PARKING MASTER VIEW
    parkingMasterView: builder.query({
      query: (id) => URL.PARKING_MASTER_VIEW + id,
    }),

    // PARKING MASTER ADD
    parkingMasterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_PARKING_MASTER,
        body: payload,
        method: "POST",
      }),
    }),
    // PARKING MASTER UPDATE
    parkingMasterUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.PARKING_MASTER_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),

    // SUSPECT MASTER LIST
    suspectmasterlist: builder.mutation({
      query: (payload) => ({
        url: URL.SUSPECT_MASTER_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    //SUSPECT_MASTER_ADD
    suspectMasterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.SUSPECT_MASTER_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    //SUSPECT_MASTER_UPDATE
    suspectMasterUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.SUSPECT_MASTER_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),

    // SUSPECT MASTER VIEW
    suspectMasterView: builder.query({
      query: (id) => URL.SUSPECT_MASTER_VIEW + id,
    }),
    // SUSPECT MASTER STATUS
    suspectMasterStatus: builder.query({
      query: (id) => URL.SUSPECT_MASTER_STATUS + id + "/status",
    }),
    // SUSPECT MASTER DELETE
    suspectMasterDelete: builder.query({
      query: (id) => URL.SUSPECT_MASTER_DELETE + id,
    }),

    // SUSPECT LIST
    suspectList: builder.mutation({
      query: (payload) => ({
        url: URL.SUSPECT_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    // SUSPECT LIST RANGE
    suspectListRange: builder.mutation({
      query: (payload) => ({
        url: URL.SUSPECT_LIST_RANGE,
        body: payload,
        method: "POST",
      }),
    }),

    // WAREHOUSE MASTER LIST
    warehousemasterlist: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_VEHICLE_MASTER_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE MASTER LIST
    warehousemasterPersonlist: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_MASTER_PERSON,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE MASTER STATUS
    warehouseMasterStatus: builder.query({
      query: (id) => URL.WAREHOUSE_MASTER_STATUS + id + "/status",
    }),
    // WAREHOUSE MASTER PERSON STATUS
    warehouseMasterPersonStatus: builder.query({
      query: (id) => URL.WAREHOUSE_MASTER_PERSON_STATUS + id + "/status",
    }),

    // WAREHOUSE MASTER VIEW
    warehouseMasterView: builder.query({
      query: (id) => URL.WAREHOUSE_MASTER_VIEW + id,
    }),
    // WAREHOUSE MASTER DELETE
    warehouseMasterDelete: builder.query({
      query: (id) => URL.WAREHOUSE_MASTER_DELETE + id,
    }),

    // WAREHOUSE MASTER PERSON DELETE
    warehouseMasterPersonDelete: builder.query({
      query: (id) => URL.WAREHOUSE_MASTER_PERSON_DELETE + id,
    }),

    //WAREHOUSE_MASTER_ADD
    warehouseMasterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_MASTER_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    //WAREHOUSE_MASTER_PERSON_ADD
    warehouseMasterPersonAdd: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_MASTER_PERSON_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    //WAREHOUSE_MASTER_PERSON_EDIT
    warehouseMasterPersonEdit: builder.mutation({
      query: ({ payload, ID }) => ({
        url: URL.WAREHOUSE_MASTER_PERSON_EDIT + ID,
        body: payload,
        method: "POST",
      }),
    }),

    //WAREHOUSE_MASTER_UPDATE
    warehouseMasterUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.WAREHOUSE_MASTER_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),
    // PARKING LIST TIMINGS
    parkingTimingList: builder.mutation({
      query: (payload) => ({
        url: URL.PARKING_LIST_TIMINGS,
        body: payload,
        method: "POST",
      }),
    }),
    // PARKING LIST TIMINGS RANGE
    parkingTimingListRange: builder.mutation({
      query: (payload) => ({
        url: URL.PARKING_LIST_TIMING_DATE_RANGE,
        body: payload,
        method: "POST",
      }),
    }),

    // PARKING LIST LOGS RANGE
    parkingLogsListRange: builder.mutation({
      query: (payload) => ({
        url: URL.PARKING_LIST_LOGS_DATE_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE LIST TIMINGS
    warehouseTimingList: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_LIST_TIMINGS,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE PERSON TIMINGS
    warehousePersonTiming: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_PERSON_LIST_TIMINGS,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE PERSON LOGS
    warehousePersonLogs: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_PERSON_LIST_LOGS,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE LIST LOGS RANGE
    warehouseLogsListRange: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_LIST_LOGS_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE LIST TIMINGS RANGE
    warehouseTimingListRange: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_LIST_TIMING_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE VEHICLE LOGS RANGE
    warehouseVehicleLogRange: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_VEHICLE_LOGS_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE VEHICLE TIMINGS RANGE
    warehouseVehicleTimingRange: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_VEHICLE_TIMING_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    // PARKING LIST LOGS
    parkingLogsList: builder.mutation({
      query: (payload) => ({
        url: URL.PARKING_LIST_LOGS,
        body: payload,
        method: "POST",
      }),
    }),
    // WAREHOUSE LIST LOGS
    warehouseLogsList: builder.mutation({
      query: (payload) => ({
        url: URL.WAREHOUSE_LIST_LOGS,
        body: payload,
        method: "POST",
      }),
    }),
    // VIP LIST
    vipList: builder.mutation({
      query: (payload) => ({
        url: URL.VIP_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    // TRESPASSING MASTER LIST
    trespassingMasterList: builder.mutation({
      query: (payload) => ({
        url: URL.TRESPASSING_MASTER,
        body: payload,
        method: "POST",
      }),
    }),
    //TRESPASSING LIST
    trespassingList: builder.mutation({
      query: (payload) => ({
        url: URL.TRESPASSING_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    // TRESPASSING MASTER DELETE
    trespassingMasterDelete: builder.query({
      query: (id) => URL.TRESPASSING_MASTER_DELETE + id,
    }),
    //TRESPASSING MASTER CREATE
    trespassingCreateList: builder.mutation({
      query: (payload) => ({
        url: URL.TRESPASSING_MASTER_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    //TRESPASSING MASTER UPDATE
    trespassingMasterUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.TRESPASSING_MASTER_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),
    //TRESPASSING List Timing
    trespassingTiming: builder.mutation({
      query: (payload) => ({
        url: URL.TRESPASSING_LIST_TIMING,
        body: payload,
        method: "POST",
      }),
    }),
    // TRESPASSING LOGS
    trespassingLogs: builder.mutation({
      query: (payload) => ({
        url: URL.TRESPASSING_LIST_LOGS,
        body: payload,
        method: "POST",
      }),
    }),
    // DEVICES
    devicesList: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    // SETTINGS
    settingList: builder.query({
      query: () => URL.SETTING_LIST,
    }),

    //SETTINGS LIST UPDATE
    settingListUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: URL.SETTING_LIST_UPDATE + ID,
        body: payload,
        method: "POST",
      }),
    }),

    // BILLING_RECORDS
    billingMasterRecords: builder.mutation({
      query: (payload) => ({
        url: URL.BILLING_MASTER_RECORDS,
        body: payload,
        method: "POST",
      }),
    }),

    // BILLING_MASTER_SHOW
    billingMasterShow: builder.query({
      query: (id) => `${URL.BILLING_MASTER_SHOW}${id}`,
    }),

    // BILLING_MASTER_DELETE
    billingMasterDelete: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.BILLING_MASTER_DELETE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),

    // BILLING MASTER ADD
    billingMasterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.BILLING_MASTER_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    //BILLING_LIST-RANGE
    billingListRange: builder.mutation({
      query: (payload) => ({
        url: URL.BILLING_LIST_RANGE,
        body: payload,
        method: "POST",
      }),
    }),

    //BILLING MASTER UPDATE

    billingMasterUpdate: builder.mutation({
      query: ({ payload, ID }) => ({
        url: `${URL.BILLING_MASTER_UPDATE}${ID}`,
        body: payload,
        method: "POST",
      }),
    }),
    //BILLING MASTER UPDATE
    billingList: builder.mutation({
      query: (payload) => ({
        url: URL.BILLING_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    //BILLING MASTER VIEW
    billingListView: builder.query({
      query: (id) => `${URL.BILLING_MASTER_VIEW}${id}`,
    }),
    //ROLES LIST
    rolesList: builder.query({
      query: () => URL.ROLES_LIST,
    }),
    //ROLES LIST
    rolesListView: builder.query({
      query: (id) => URL.ROLES_LIST_VIEW + id,
    }),

    //ROLES LIST Delete
    rolesListDelete: builder.query({
      query: (role) => URL.ROLES_LIST_DELETE + role,
    }),
    //ROLES LIST Permissions
    rolesListPermissions: builder.query({
      query: () => URL.ROLES_LIST_PERMISSIONS,
    }),
    //ROLES LIST ADD
    rolesListAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ROLES_LIST_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    //ROLES LIST EDIT
    rolesListEdit: builder.mutation({
      query: ({ payload, ID }) => ({
        url: URL.ROLES_LIST_EDIT + ID,
        body: payload,
        method: "POST",
      }),
    }),
    //DATE RANGE
    dateRange: builder.mutation({
      query: (payload) => ({
        url: URL.DATE_RANGE,
        body: payload,
        method: "POST",
      }),
    }),
    //TIMING DATE RANGE
    timingDateRange: builder.mutation({
      query: (payload) => ({
        url: URL.TIMING_DATE_RANGE,
        body: payload,
        method: "POST",
      }),
    }),

    //Overall Crowd
    overallCrowd: builder.query({
      query: (id) => ({
        url: URL.OVERALL_CROWD + id,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useLazyLocationListQuery,
  useLocationAddMutation,
  useLocationDevicesAddMutation,
  useLazyLocationCameraDeleteQuery,
  useLocationDevicesUpdateMutation,
  useLocationDeleteMutation,
  useLazyLocationCameraListQuery,
  useLocationCameraAddMutation,
  useLocationCameraPointMutation,
  useLocationCameraUpdateMutation,
  useLazyLocationCameraViewQuery,
  useLocationEditMutation,
  useLazyLocationViewQuery,
  useLazyVipMasterCompleteListQuery,
  useAddAdminMutation,
  useLazyAdminStatusQuery,
  useLazyListAdminQuery,
  useLazyViewAdminQuery,
  useUpdateAdminMutation,
  useVipmasterlistMutation,
  useLazyVipMasterViewQuery,
  useLazyVipMasterStatusQuery,
  useLazyVipMasterDeleteQuery,
  useVipMasterUpdateMutation,
  useVipmasterlistRangeMutation,
  useVipMasterAddMutation,
  useParkingmasterlistMutation,
  useParkingTimingListRangeMutation,
  useLazyParkingMasterStatusQuery,
  useParkingLogsListRangeMutation,
  useLazyParkingMasterDeleteQuery,
  useParkingMasterUpdateMutation,
  useParkingMasterAddMutation,
  useLazyParkingMasterViewQuery,
  useLazyWarehouseMasterViewQuery,
  useLazyWarehouseMasterPersonStatusQuery,
  useLazyWarehouseMasterPersonDeleteQuery,
  useSuspectmasterlistMutation,
  useLazySuspectMasterStatusQuery,
  useLazySuspectMasterDeleteQuery,
  useSuspectMasterAddMutation,
  useSuspectListRangeMutation,
  useSuspectMasterUpdateMutation,
  useWarehousemasterlistMutation,
  useLazyWarehouseMasterStatusQuery,
  useLazyWarehouseMasterDeleteQuery,
  useParkingTimingListMutation,
  useWarehouseTimingListMutation,
  useWarehouseMasterAddMutation,
  useWarehouseMasterUpdateMutation,
  useWarehouseLogsListRangeMutation,
  useWarehouseVehicleLogRangeMutation,
  useWarehouseVehicleTimingRangeMutation,
  useWarehousePersonTimingMutation,
  useWarehousePersonLogsMutation,
  useWarehouseTimingListRangeMutation,
  useParkingLogsListMutation,
  useWarehouseLogsListMutation,
  useVipListMutation,
  useSuspectListMutation,
  useTrespassingMasterListMutation,
  useLazySuspectMasterViewQuery,
  useTrespassingListMutation,
  useLazyTrespassingMasterDeleteQuery,
  useTrespassingCreateListMutation,
  useTrespassingMasterUpdateMutation,
  useTrespassingTimingMutation,
  useTrespassingLogsMutation,
  useDevicesListMutation,
  useLazySettingListQuery,
  useSettingListUpdateMutation,
  useBillingMasterRecordsMutation,
  useLazyBillingMasterShowQuery,
  useBillingMasterAddMutation,
  useBillingMasterUpdateMutation,
  useBillingMasterDeleteMutation,
  useBillingListMutation,
  useBillingListRangeMutation,
  useLazyBillingListViewQuery,
  useLazyRolesListQuery,
  useLazyRolesListDeleteQuery,
  useRolesListAddMutation,
  useRolesListEditMutation,
  useLazyRolesListPermissionsQuery,
  useDateRangeMutation,
  useTimingDateRangeMutation,
  useLazyOverallCrowdQuery,
  useLazyRolesListViewQuery,
  useWarehousemasterPersonlistMutation,
  useWarehouseMasterPersonAddMutation,
  useWarehouseMasterPersonEditMutation,
  useLazyPointListQuery,
  useLocationConfigAddMutation,
  useLocationConfigEditMutation,
  useLocationConfigDeleteMutation,
  useLocationConfigListMutation,
  useLocationConfigViewMutation,
  useSshStatusMutation,
} = api;
