import React, { useState } from 'react'
import { logo, parallel_logo, profile } from '../../assets/imgs';

const Topbar = () => {

    return (
        <div className=' topbar '>
            <div className='w-100 d-flex ac-jb border-bot p-2 px-3'>
                <div className='d-flex ac-js'>
                    <img src={logo} className='logo' alt="" />
                    <span>
                        <p className='f3 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 mb-0'> Parallel App: Pay, Manage Bills, Exclusive Offers</p>
                        <p className='f3 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 sec mb-0'>Open in the Parallel App</p>
                    </span>
                </div>
                <button className='button f3 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-22'>
                    OPEN
                </button>
            </div>
            <div className='bg-gray1 p-3 border-bot text-center'>
                <h4 className='f4 fs-sm-12 fs-md-14 fs-lg-20 fs-xl-22 fs-xxl-24 mb-0'> Find your Parking Lot</h4>
                <p className=' f3 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  mb-0'> Powered by <img src={parallel_logo} className='parallel-logo' alt="" /></p>
            </div>
        </div>
    )
}

export default Topbar