import React from "react";
import { Route, Routes } from "react-router-dom";
import "../src/assets/style/color.scss";
import "../src/assets/style/custome.scss";
import "../src/assets/style/fonts.scss";
import "../src/assets/style/height.scss";
import "../src/assets/style/responsive.scss";
import "../src/assets/style/style.scss";
import "../src/assets/style/width.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import LotID from "./screens/LOTID/LotID";
import Layout from "./components/Layouts/Layout";
import LicencePlate from "./screens/LOTID/LicencePlate";
import AlreadyAutopay from "./screens/Autopay/AlreadyAutopay";
import SetupAutopay from "./screens/Autopay/SetupAutopay";
import ConfirmAutopay from "./screens/Autopay/ConfirmAutopay";
import AutopaySuccess from "./screens/Autopay/AutopaySuccess";
import PayBill from "./screens/Autopay/PayBill";
import PaybyCard from "./screens/Autopay/PaybyCard";
import PaymentSucess from "./screens/Autopay/PaymentSucess";
import PlateNotRead from "./screens/PlateNotRead/PlateNotRead";
import PayNow from "./screens/PlateNotRead/PayNow";
import PayNowSuccess from "./screens/PlateNotRead/PayNowSuccess";
import PlateAutopayOn from "./screens/PlateNotRead/PlateAutopayOn";
import PayNowAutopayOn from "./screens/PlateNotRead/PayNowAutopayOn";
import OnRegistry from "./screens/PlateNotRead/OnRegistry";




const ReactRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LotID />} />
      <Route path="/licence-plate" element={<LicencePlate />} />
      <Route element={<Layout />}>
        <Route path="/already-autopay" element={<AlreadyAutopay />} />
        <Route path="/setup-autopay" element={<SetupAutopay />} />
        <Route path="/confirm-autopay" element={<ConfirmAutopay />} />
        <Route path="/autopay-success" element={<AutopaySuccess />} />
        <Route path="/paybill" element={<PayBill/>}/>
        <Route path='/paybycard' element={<PaybyCard/>}/>
        <Route path="/payment-success" element={<PaymentSucess/>}/> 
        <Route path="/plate-not-read" element={<PlateNotRead/>}/>
        <Route path="/pay-now" element={<PayNow/>}/>
        <Route path="/pay-now-success" element={<PayNowSuccess/>}/>
        <Route path="/plate-autopay-on" element={<PlateAutopayOn/>}/>
        <Route path="/paynow-autopay-on" element={<PayNowAutopayOn/>}/>
        <Route path="/on-registry" element={<OnRegistry/>}/>
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
